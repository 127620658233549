import "./App.scss";
import "./scss/Main.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./views/Home";
import Layout from "./components/Layout";
import About from "./views/About";
import Contact from "./views/Contact";

const TestComponent = () => {
  return (
    <Layout>
      <p>Test Component</p>
    </Layout>
  );
};

const App = () => (
  <Router>
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </div>
  </Router>
);

export default App;
