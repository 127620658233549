import React from "react";
import Contact from "../components/Contact";
import Layout from "../components/Layout";

const ContactView = () => (
  <Layout>
    <Contact />
  </Layout>
);

export default ContactView;
