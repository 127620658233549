import React from "react";

const ContactComp = () => {
  return (
    <div>
      <h2>Contact</h2>
      <p>
        Click{" "}
        <a
          href="mailto:kvining@mail.com?subject=Enquiry from website"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{" "}
        to send me an email.
      </p>
    </div>
  );
};

export default ContactComp;
