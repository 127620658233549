import React from "react";
import Home from "../components/Home";
import Layout from "../components/Layout";

const HomeView = () => (
  <Layout>
    <Home />
  </Layout>
);

export default HomeView;
