import React from "react";
import StackGrid from "react-stack-grid";
import {
  picEight,
  picFive,
  picFour,
  picNine,
  picOne,
  picSeven,
  picSix,
  picThree,
  picTwo,
} from "../assets/images";

const HomeComp = () => {
  return (
    <StackGrid
      columnWidth={300}
      gutterWidth={15}
      gutterHeight={15}
      monitorImagesLoaded
    >
      <div className="tile">
        <img src={picOne} width="300" alt="doge" />
      </div>
      <div className="tile">
        <img src={picTwo} width="300" alt="doge" />
      </div>
      <div className="tile">
        <img src={picEight} width="300" alt="doge" />
      </div>
      <div className="tile">
        <img src={picThree} width="300" alt="doge" />
      </div>
      <div className="tile">
        <img src={picFour} width="300" alt="doge" />
      </div>
      <div className="tile">
        <img src={picFive} width="300" alt="doge" />
      </div>
      <div className="tile">
        <img src={picSeven} width="300" alt="doge" />
      </div>
      <div className="tile">
        <img src={picSix} width="300" alt="doge" />
      </div>
      <div className="tile">
        <img src={picNine} width="300" alt="doge" />
      </div>
    </StackGrid>
  );
};

export default HomeComp;
