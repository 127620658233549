import React from "react";
import Sidebar from "./Sidebar";

const Layout = ({ children, sidebar = true }) => (
  <div className="layout-container">
    {sidebar && <Sidebar />}
    <main className="main">{children}</main>
  </div>
);

export default Layout;
