import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h1 className="heading">Handmade by Katie</h1>
      <ul className="nav">
        <li>
          <NavLink exact activeClassName="active" className="nav-item" to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeClassName="active"
            className="nav-item"
            to="/about"
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeClassName="active"
            className="nav-item"
            to="/contact"
          >
            Contact
          </NavLink>
        </li>
      </ul>
      <footer className="footer">
        <p>
          website by{" "}
          <a
            href="https://portfolio.dforder.com"
            target="_blank"
            rel="noreferrer"
          >
            dan forder
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Sidebar;
