import React from "react";

const AboutComp = () => {
  return (
    <div>
      <h2>About</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur id
        bibendum lacus, id iaculis mi. Curabitur sed tortor at magna bibendum
        iaculis vitae eu neque. Proin eleifend facilisis lorem, id sodales dui
        sagittis a. Praesent ullamcorper sollicitudin dui, vitae euismod velit
        faucibus at. In venenatis tortor tempus, porttitor leo et, fringilla
        massa. Curabitur porta massa at mattis consectetur. Nunc fringilla
        cursus accumsan. Etiam finibus metus non commodo venenatis. Aenean quis
        purus nec augue egestas dictum.
      </p>
      <p>
        Sed sit amet orci justo. Mauris malesuada quis erat a semper. Proin in
        nulla eget sapien rutrum imperdiet. Donec ullamcorper sagittis velit.
        Nam in vestibulum turpis. Etiam hendrerit lectus ut lobortis finibus.
        Suspendisse varius, nulla non suscipit fringilla, ligula erat rutrum
        velit, ac sodales est est et leo. Nullam et lacinia mi, nec varius sem.
        Nullam rutrum pellentesque nibh, sed dapibus mi tempor eget. Donec eu
        condimentum dui. Sed quis diam dapibus, bibendum erat et, eleifend elit.
        Aenean maximus diam vitae sapien iaculis, non eleifend enim convallis.
      </p>
      <div className="about_image_wrapper">
        <img
          className="responsive"
          src="https://placedog.net/435/275"
          alt="doge"
        />
      </div>
    </div>
  );
};

export default AboutComp;
